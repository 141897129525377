import React from 'react'
import { path } from './constants'

function Aboutus() {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [])

  return (
    <div>
      {/* First Section */}
      <div class="container-fluid heading" style={{ paddingTop: "50px" }} >
        <div class="block">
          <div class="inner width-1">
            <div class="page-headings style2 p-auto m-auto">
              <hr id="anchor" style={{ borderColor: "white !important", color: "white !important" }} data-aos="fade-right" />
              <h1 class="heading-x-large" data-aos="fade-left" data-aos-delay="500" data-aos-anchor="#anchor">Work on good prose has three steps:</h1>
              <p data-aos="fade-right" data-aos-delay="1000" data-aos-anchor="#anchor">a musical stage when it is composed, an architectonic one when it is build, and a textile one when it is woven</p>
              <h6 style={{ float: "right" }} data-aos="fade-left" data-aos-delay="1300" data-aos-duration="1000" data-aos-anchor="#anchor">- Walter Benjamin</h6>
            </div>
          </div>
        </div>
      </div>
      {/* Second Section */}
      <div className="container pt-5">
        <div class="container">
          <h2 id="HnP" class="pb-3 pt-2 border-bottom pb-5" data-aos="fade-up" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>History & Progression</h2>
          <div className="row" data-aos-anchor="#HnP" data-aos="fade-down">
            <div className="col-12 col-md-8 m-auto p-auto" style={{ textAlign: "justify", textAlignLast: "center" }}>
              <p>
                Growing and developing on the foundations laid by a tightly knit family for more than 30 years, Ashima Fabrics is a leading textile enterprise based in Surat, the textile hub of India.
              </p>
              <p>Looking for a bigger platform to build on the entrepreneurial spirit inherited from their forefathers, our founders(Sanjay Mangukiya) arrived in Surat in 1989 and set up a small textile unit. With the unwavering vision to be a fully integrated textile enterprise, and the strong support from our workforce we have since expanded into grey fabrics, weaving, finish fabrics and industrial infrastructure.
              </p>
            </div>
          </div>
        </div>
      </div>


      {/* THIRD SECTION - VISION MISSION */}
      <div>
        <section id="features" class="features">
          <div class="container">

            {/* <div class="section-title">
              <h2>Features</h2>
              <p>Check our Features</p>
            </div> */}

            <div class="row">
              <div class="col-lg-3" data-aos="fade-right">
                <ul class="nav nav-tabs flex-column">
                  <li class="nav-item">
                    <a class="nav-link active show" data-toggle="tab" href="#tab-1">Our Vision</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tab-2">Our Mission</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tab-3">Our Cultural Values</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-9 mt-4 mt-lg-0" data-aos="fade-left">
                <div class="tab-content">
                  <div class="tab-pane active show" id="tab-1">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1" style={{ paddingTop: "50px" }}>
                        <p className="section-3-aboutus-p">"To be one of the world’s leading integrated textile enterprise exceeding customer expectations, driven by research, innovation and excellence."</p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img src={`${path}/img/features-1.png`} alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-2">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1">
                        <p style={{ lineHeight: "30px" }} className="section-3-aboutus-p">- To ensure the highest level of customer satisfaction .
                        <br />- Innovate constantly in our business to be a market leader
                        <br />- Optimise the use of all resources, through state of the art technology
                        <br />- Surpass compliance and global quality standards
                        <br />- Actively explore and tap potential markets and products
                        <br />- Become a process driven organisation
                        <br />- Expand people development initiatives
                        <br />- Be an ethical, transparent and a responsible global organisation</p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img src={`${path}/img/features-2.png`} alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-3">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1">
                        <p style={{ lineHeight: "30px" }} className="section-3-aboutus-p">- Customer satisfaction
                      <br />- Visionary leadership
                      <br />- Passionate about creativity, innovation and excellence
                      <br />- Adaptable and open to change, thus breaking the statusquo
                      <br />- Concern for the environment and the community
                      <br />- Ethical, transparent and global corporate citizens
                      <br />- Fulfilling corporate social responsibilities</p>
                      </div>
                      <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img src={`${path}/img/features-3.png`} alt="" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
      {/* FOURTH SECTION - R & D */}
      <div className="container pt-5">
        <div class="container">
          <h2 id="RnD" class="pb-3 pt-2 border-bottom pb-5" data-aos="fade-up" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>Research & Development</h2>
          <div className="row" data-aos-anchor="#RnD" data-aos="fade-down">
            <div className="col-12 col-md-8 m-auto p-auto" style={{ textAlign: "justify", textAlignLast: "center" }}>
              <p>
                Product quality is of supreme importance for us at Ashima. It is also one of the major reasons why our products are recognised and accepted by some of the world’s most notable and demanding customers.
              </p>
              <p>
                We have invested in an extensive and rigorous R&D infrastructure to ensure proper testing methods, to adhere to all quality specifications. This infrastructure incorporates modern equipment for the entire design and development process.
              </p>
              <p>
                A team of highly qualified professionals are engaged in staying abreast of the ever-changing technology through visits to various technical seminars & industries in India and overseas, along with constant guidance from experts from the textile industry.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <h2 id="Range" data-aos="fade-up" class="pb-3 pt-5 border-bottom pb-5" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>
              Chairs:
            </h2>
          </div>

          <div className="col-12 col-md-6">
            <div class="card my-5 mx-auto p-auto" style={{ width: "20rem" }}>
              {/* <img class="card-img-top" src="..." alt="Card image cap" /> */}
              <div class="card-body">
                <h5 class="card-title">Mr. Sanjay Mangukiya</h5>
                <p class="card-text">Designation : Founder</p>
                <p class="card-text">Contact : +91 98251 40452</p>
                <p class="card-text">Email : ashimafarics29@gmail.com</p>
                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 align-items-start">
            <div class="card my-5 mx-auto p-auto" style={{ width: "20rem" }}>
              {/* <img class="card-img-top" src="..." alt="Card image cap" /> */}
              <div class="card-body">
                <h5 class="card-title">Mr. Manav Mangukiya</h5>
                <p class="card-text">Designation : CEO</p>
                <p class="card-text">Contact : +91 97123 40452</p>
                <p class="card-text">Email : manav.ashima@gmail.com</p>
                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Aboutus
