import React from 'react'
import { path } from './constants'

function Strengths() {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [])

  return (
    <div>
      <div style={{ paddingTop: "80px" }}>
        <div class="container-fluid strengths-heading" style={{ paddingTop: "100px", height: "450px" }} >
          <div class="block">
            <div class="inner width-1">
              <div class="page-headings style2 p-auto m-auto">
                <center>
                  <h1 class="heading-x-large" data-aos="fade-left" data-aos-delay="500">Our Strengths</h1>
                </center>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container pt-5">
          <h2 id="Range" data-aos="fade-down" class="pb-3 pt-5 border-bottom pb-5" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>
            Our Strengths:
          </h2>
        </div> */}
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-md-6 col-12" style={{ textAlign: "center" }}>
              <img src={`${path}/img/tech.jpeg`} height="400px" width="300px" data-aos="fade-up"></img>
            </div>
            <div className="col-md-6 col-12 p-auto m-auto pt-5" style={{ verticalAlign: "middle" }} >
              <div data-aos="fade-down">
                <h1>TECHNOLOGY & MANUFACTURING</h1>
                <hr></hr>
                <p style={{ textAlign: "justify" }}>
                  At Ashima, we continuously invest in state of the art technology and equipment across all our areas of operations to adopt globally accepted manufacturing technology, delivering speed, versatility and most importantly, quality.
            </p>
              </div>
            </div>
            <div className="col-md-6 col-12 p-auto m-auto pt-5" style={{ verticalAlign: "middle" }} >
              <div data-aos="fade-up">
                <h1>QUALITY</h1>
                <hr></hr>
                <p style={{ textAlign: "justify" }}>Product quality is of supreme importance for us at Ashima. We ensure exceptional quality control across our entire manufacturing process through extensive testing methods, to adhere to all quality specifications.</p>
              </div>
            </div>
            <div className="col-md-6 col-12 pt-5" style={{ textAlign: "center" }} >
              <img src={`${path}/img/quality.jpeg`} height="400px" width="300px" data-aos="fade-down"></img>
            </div>
            <div className="col-md-6 col-12  pt-5" style={{ textAlign: "center" }} >
              <img src={`${path}/img/10.jpg`} height="400px" width="300px" data-aos="fade-up"></img>
            </div>
            <div className="col-md-6 col-12 p-auto m-auto  pt-5" style={{ verticalAlign: "middle" }} >
              <div data-aos="fade-down">
                <h1>Support</h1>
                <hr></hr>
                <p style={{ textAlign: "justify" }}>
                  Our ‘quality-first’ approach extends to all our areas of operations, including customer support. From understanding the basic requirements of our customers to offering pre-sales and postsales service, the highly responsive and dedicated team at Ashima ensures we continuously exceed customer expectations.
              </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Strengths
