import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import Home from './components/Home';
import Aboutus from './components/Aboutus';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Strengths from './components/Strengths';
import CSR from './components/CSR';
import Business from './components/Business';
import Totop from './components/Totop';
import Splash from './components/Splash';
// import { AnimatedSwitch } from 'react-router-transition';


function App() {

  const [loading, setLoading] = useState(true);
  // const [opacity, setopacity] = useState(1)
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  // useEffect(() => {
  //   setTimeout(() => {
  //     document.getElementById('splash').style.opacity = 0;
  //   }, 1000);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // }, [])

  return (
    <>
      {/* {loading && <Splash />} */}
      <Header />
      <Totop></Totop>
      <Route exact path="/"><Home /></Route>
      <Route exact path="/aboutus"><Aboutus /></Route>
      <Route exact path="/contact"><Contact /></Route>
      <Route exact path="/business"><Business /></Route>
      <Route exact path="/strengths"><Strengths /></Route>
      <Route exact path="/csr"><CSR /></Route>
      <Footer />
    </>
  );
}

export default App;
