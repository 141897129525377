/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import {
  Link,
  NavLink
} from "react-router-dom";
import "../styles/Header.css";

import { path } from './constants';

function Header() {
  return (
    <>
      <header id="header" class="fixed-top shadow bg-white">
        <div class="container-fluid  align-items-center">
          <nav class="navbar navbar-expand-lg navbar-light py-0">
            <div><Link to="/" style={{ color: "#555251" }}><img style={{ height: "50px" }} className="icon" src={`${path}/img/header.png`} /></Link></div>
            <button class="navbar-toggler" style={{ float: "right" }} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav m-auto">
                <li class="nav-item mr-5" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink className="nav-link py-0" to="/" exact activeStyle={{
                    fontWeight: "bold",
                    color: "#8B4513"
                  }}>Home</NavLink>
                </li>
                <li class="nav-item mr-5" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink className="nav-link py-0" to="/aboutus" activeStyle={{
                    fontWeight: "bold",
                    color: "#8B4513"
                  }}>About Us </NavLink>
                </li>
                <li class="nav-item mr-5" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink className="nav-link py-0" to="/business" activeStyle={{
                    fontWeight: "bold",
                    color: "#8B4513"
                  }}>Business</NavLink>
                </li>
                <li class="nav-item mr-5" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink className="nav-link py-0" to="/strengths" activeStyle={{
                    fontWeight: "bold",
                    color: "#8B4513"
                  }}>Strengths</NavLink>
                </li>
                <li class="nav-item mr-5" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink className="nav-link py-0" to="/csr" activeStyle={{
                    fontWeight: "bold",
                    color: "#8B4513"
                  }}>CSR</NavLink>
                </li>
                <li class="nav-item mr-5" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <NavLink className="nav-link py-0" to="/contact" activeStyle={{
                    fontWeight: "bold",
                    color: "#8B4513"
                  }}>Contact</NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header
