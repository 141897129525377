
import React, { useState, useEffect } from 'react'
import useScroll from './useScroll';

function scrollToTop(scrollDuration) {
  // var i = 1;
  // var scrollStep = -window.scrollY / (scrollDuration / 15),
  //   scrollInterval = setInterval(function () {
  //     if (window.scrollY != 0) {
  //       window.scrollBy(0, scrollStep * i);
  //       i += 0.1
  //     }
  //     else clearInterval(scrollInterval);
  //   }, 15);
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

function Totop() {
  const { scrollX, scrollY, scrollDirection } = useScroll();
  const [flag, setflag] = useState(false);
  const y = window.innerHeight;
  useEffect(() => {
    // console.log(scrollY)
    if (scrollY > y - 500) {
      setflag(true)
    } else {
      setflag(false)
    }
    console.log(flag)
  }, [scrollY]);
  return (
    flag && <button style={{
      position: "fixed",
      // borderRadius: "50%",
      zIndex: 2000,
      height: 30,
      width: 30,
      right: "20px",
      bottom: 30,
      padding: 0,
      margin: 0,
      textAlign: "center",
      verticalAlign: "middle",
      fontWeight: 1000,
      background: "none",
      border: "none",
      outline: "none"

    }}
      onClick={
        e => {
          e.preventDefault();
          scrollToTop(2000);
        }
      }
    >
      <img src="assets/img/svg/up-arrow.svg" ></img>
    </button>
  )
}

export default Totop
