import React from 'react'
import { path } from './constants'

function CSR() {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [])

  return (
    <div style={{ paddingTop: "80px" }}>
      <div class="container-fluid csr-heading" style={{ paddingTop: "100px", height: "450px" }} >
        <div class="block">
          <div class="inner width-1">
            <div class="page-headings style2 p-auto m-auto">
              <center>
                <h1 class="heading-x-large" data-aos="fade-left" data-aos-delay="500">Corporate Social Responsibilities</h1>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div>
          <h2 id="SuS" class="pb-3 pt-5 border-bottom pb-5" data-aos="fade-up" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>Sustainability</h2>
          <div className="row" data-aos-anchor="#SuS" data-aos="fade-down">
            <div className="col-12 col-md-8 m-auto p-auto" style={{ textAlign: "justify", textAlignLast: "center" }}>
              <p>
                At Ashima, we understand the importance of protecting the environment we operate in and being humble towards the planet and local communities is a key aspect of our culture.
              </p>
              <p>From the beginning, we have focused on reducing our footprint on the planet, and three windmills help us significantly offset the electricity consumption of our units. We have also installed <strong> an Effluent Treatment Plant (ETP) with the capacity to recycle 15 lakh litres of water every day,</strong> completely eliminating the discharge from water-jet weaving. Further, <strong>the incorporation of a zero liquid discharge dyeing process</strong> keeps pollution in check.
              </p>
              <p>Other initiatives, such as the development of green zones in and around our units and offices, waste reduction and safe disposal, and compliance with environmental laws, all contribute in making us one of the few environmentally friendly textile enterprises in the country.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <div className="row">
          <div className="col-md csr-images" data-aos="flip-left" data-aos-duration="700">
            <img src={`${path}/img/11.jpg`} height="200px" />
          </div>
          <div className="col-md csr-images" data-aos="zoom-in" data-aos-duration="700">
            <img src={`${path}/img/14.jpg`} height="200px" />
          </div>
          <div className="col-md csr-images" data-aos="flip-right" data-aos-duration="700">
            <img src={`${path}/img/19.jpg`} height="200px" width="325px" />
          </div>
        </div>

      </div>

    </div>
  )
}

export default CSR
