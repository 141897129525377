import React from 'react'
import {
    NavLink
} from "react-router-dom";

function Footer() {
    return (
        <footer id="footer" style={{ paddingBottom: "0", backgroundColor: "#5a402e", marginTop: "50px" }}>
            <div class="footer-top" style={{ backgroundColor: "#815E3B" }}>
                <div class="container" >
                    <div class="row">

                        <div class="col-lg-3 col-md-6">
                            <div class="footer-info">
                                <h3>Ashima Fabrics</h3>
                                <p style={{ textAlign: "left", textJustify: "inter-word", fontSize: "13px", lineHeight: "17px" }}>
                                    Our Company started way back in 1989 as a textile processing unit under the leadership of Shri Sanjaybhai Mangukiya, has over the years grown into a vertically integrated enterprise having manufacturing facilities for grey fabrics, weaving and finish fabrics.
                                    </p>
                                {/* <p>
                                        A108 Adam Street <br />
                                            NY 535022, USA<br /><br />
                                        <strong>Phone:</strong> +1 5589 55488 55<br />
                                        <strong>Email:</strong> info@example.com<br />
                                    </p> */}
                                {/* <div class="social-links mt-3">
                                    <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                                    <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                                    <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                                    <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                                    <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                                </div> */}
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <NavLink className="nav-link py-0" to="/" exact>Home</NavLink></li>
                                <li><i class="bx bx-chevron-right"></i>  <NavLink className="nav-link py-0" to="/aboutus" exact>About us</NavLink></li>
                                <li><i class="bx bx-chevron-right"></i> <NavLink className="nav-link py-0" to="/business" exact>Business</NavLink></li>
                                <li><i class="bx bx-chevron-right"></i> <NavLink className="nav-link py-0" to="/strengths" exact>Strenghts</NavLink></li>
                                <li><i class="bx bx-chevron-right"></i>  <NavLink className="nav-link py-0" to="/csr" exact>CSR</NavLink></li>
                                <li><i class="bx bx-chevron-right"></i>  <NavLink className="nav-link py-0" to="/contact" exact>Contact</NavLink></li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i><NavLink className="nav-link py-0" to="/business" exact> Grey fabrics</NavLink></li>
                                <li><i class="bx bx-chevron-right"></i> <NavLink className="nav-link py-0" to="/business" exact>Weaving</NavLink></li>
                                <li><i class="bx bx-chevron-right"></i> <NavLink className="nav-link py-0" to="/business" exact>Finish fabrics</NavLink></li>
                            </ul>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-newsletter">
                            <div style={{ width: "100%" }}>
                                <div style={{ widht: "50%", float: "left" }}>
                                    {/* <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z" />
                                        <path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                    </svg> */}
                                    <img height="30em" src="/assets/img/factory.svg"></img>
                                </div>
                                <div style={{ marginLeft: "15%" }}>
                                    Factory Desk: P-1 To 8, Jay ambey Ind. Estate, Nr.Delad Gam, Sayan, Surat
                                    </div>
                            </div>
                            <br />
                            <div style={{ width: "100%" }}>
                                <div style={{ widht: "50%", float: "left" }}>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z" />
                                        <path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                    </svg>
                                </div>
                                <div style={{ marginLeft: "15%" }}>
                                    Office Desk: 238, RJD, Business Hub, B/h. Bada Ganesh, Opp. Kasanagar, Katargam, Surat
                                    </div>
                            </div>
                            <br />
                            <div style={{ width: "100%" }}>
                                <div style={{ widht: "50%", float: "left" }}>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-envelope" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                                    </svg>
                                </div>
                                <div style={{ marginLeft: "15%" }}>
                                    ashimafarics29@gmail.com<br />manav.ashima@gmail.com
                                    </div>
                            </div>
                            <br />
                            <div style={{ width: "100%" }}>
                                <div style={{ widht: "50%", float: "left" }}>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-telephone" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M3.925 1.745a.636.636 0 0 0-.951-.059l-.97.97c-.453.453-.62 1.095-.421 1.658A16.47 16.47 0 0 0 5.49 10.51a16.471 16.471 0 0 0 6.196 3.907c.563.198 1.205.032 1.658-.421l.97-.97a.636.636 0 0 0-.06-.951l-2.162-1.682a.636.636 0 0 0-.544-.115l-2.052.513a1.636 1.636 0 0 1-1.554-.43L5.64 8.058a1.636 1.636 0 0 1-.43-1.554l.513-2.052a.636.636 0 0 0-.115-.544L3.925 1.745zM2.267.98a1.636 1.636 0 0 1 2.448.153l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z" />
                                    </svg>
                                </div>
                                <div style={{ marginLeft: "15%" }}>
                                    <p>+91 97123 40452 <br />+91 98251 40452</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div class="container">
                    <div class="row" style={{ verticalAlign: "middle" }}>
                        <div className="col-12 col-md-6 p-3 m-auto text-center">
                            &copy; Copyright <b>Ashima Fabrics</b>
                        </div>
                        <div className="col-12 col-md-6 p-3 m-auto text-center">
                            Designed and Developed by Shubham & Raj
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        // </div >

    )
}

export default Footer
