/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import { path } from './constants'

function Business() {
  return (
    <div style={{ paddingTop: "75.5px" }}>
      <div class="container-fluid business-heading" style={{ height: "450px" }} >
        <div class="block">
          <div class="inner width-1">
            <div class="page-headings style2 p-auto m-auto">
              <center>
                <h1 class="heading-x-large" style={{ paddingTop: "100px" }} data-aos="fade-left">Fabrics</h1>
              </center>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <div>
          <div className="row">
            <div className="col-12 col-md-8 m-auto p-auto" style={{ textAlign: "justify", textAlignLast: "center" }}>
              <div data-aos="fade-up">
                <p>
                  At Ashima we continuously innovate and develop new fabrics designed to meet the needs of the future. We not only meet customer demands but also suggest better alternatives, giving them an edge over their competition. Our worldwide customer base helps us to produce fabrics that are suited to a wide range of conditions, from fashion to climate.
                  </p >
                <p >Our state-of-the-art manufacturing facility is designed according to international standards. And fabric sampling machines & water-jet looms.
                  </p>
                <p ><strong>We supply fashion, shirting & suiting, furnishing and technical fabrics to leading trading houses in India as well as fashion brands across the world.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <h2 id="Range" data-aos="fade-down" class="pb-3 pt-5 border-bottom pb-5" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>
          Our wide range of fabric offerings include:
        </h2>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12" style={{ textAlign: "center" }}>
            <img src={`${path}/img/6.jpg`} height="400px" width="300px" data-aos="fade-up"></img>
          </div>
          <div className="col-md-6 col-12 p-auto m-auto pt-5" style={{ verticalAlign: "middle" }} >
            <div data-aos="fade-down">
              <h1 >GREIGE FABRICS</h1>
              <hr></hr>
              <p style={{ textAlign: "justify" }}>
                We are one of the most prominent manufacturers, exporters and suppliers of a comprehensive variety of home furnishing, fancy dress material, shirting and fabrics.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12 p-auto m-auto pt-5" style={{ verticalAlign: "middle" }} >
            <div data-aos="fade-up">
              <h1>SUSTAINABLE FABRICS</h1>
              <hr></hr>
              <p style={{ textAlign: "justify" }}>We are very conscious about our impact on the environment, so in addition to our eco-friendly infrastructure, we also manufacture sustainable fabrics made from recycled yarns.</p>
            </div>
          </div>
          <div className="col-md-6 col-12 pt-5" style={{ textAlign: "center" }} >
            <img src={`${path}/img/9.jpg`} height="400px" width="300px" data-aos="fade-down"></img>
          </div>
          {/* <div className="col-md-6 col-12  pt-5" style={{ textAlign: "center" }} >
            <img src="assets/img/10.jpg" height="400px" width="300px" ></img>
          </div>
          <div className="col-md-6 col-12 p-auto m-auto  pt-5" style={{ verticalAlign: "middle" }}>
            <h1>Support</h1>
            <hr></hr>
            <p style={{ textAlign: "justify" }}>
              Our ‘quality-first’ approach extends to all our areas of operations, including customer support. From understanding the basic requirements of our customers to offering pre-sales and postsales service, the highly responsive and dedicated team at Shahlon ensures we continuously exceed customer expectations.
          </p>
          </div> */}
        </div>
      </div>

      <div className="container">
        <h2 id="Range" class="pb-3 pt-5 border-bottom pb-5" data-aos="fade-up" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>
          Weaves:
        </h2>
        <div className="row weaves" data-aos="fade-up">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/01.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Plain</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/02.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Cord</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/03.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Twill</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/04.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Satin</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/05.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Rib</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/06.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Moss</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/07.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Dobby</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/08.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Oxford</h4>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="my-3" style={{ background: `url(${path}/img/weaves/09.jpg)`, paddingTop: "150px", backgroundSize: "cover" }} >
              <h4 className="p-3" style={{ fontWeight: "bold", color: "white" }}>Herringbone</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <h2 id="Range" data-aos="fade-up" class="pb-3 pt-5 border-bottom pb-5" style={{ fontWeight: "bold", textAlign: "center", borderRadius: "50%" }}>
          Properties:
        </h2>
      </div>

      <div className="pt-5">
        <section id="features" class="features">
          <div class="container" data-aos="fade-down">
            <div class="row">
              <div class="col-lg-3" >
                <ul class="nav nav-tabs flex-column">
                  <li class="nav-item">
                    <a class="nav-link active show" data-toggle="tab" href="#tab-1" style={{ fontSize: "20px" }}>Composition</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tab-2" style={{ fontSize: "20px" }}>Denier</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#tab-3" style={{ fontSize: "20px" }}>GSM</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-9 mt-4 mt-lg-0" >
                <div class="tab-content">
                  <div class="tab-pane active show" id="tab-1">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1" style={{ paddingTop: "30px" }}>
                        <p style={{ fontSize: "18px" }}>- Polyester/Polyester Blends.
                        <br />-  Catonic/ Catonic Blends
                        <br />- Nylon/Nylon Blends</p>
                      </div>
                      {/* <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img src="assets/img/features-1.png" alt="" class="img-fluid" />
                      </div> */}
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-2">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1" style={{ paddingTop: "53px" }}>
                        <p style={{ lineHeight: "30px" }} style={{ fontSize: "18px" }}>- Ranges from 20-300 Denier</p>
                      </div>
                      {/* <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img src="assets/img/features-2.png" alt="" class="img-fluid" />
                      </div> */}
                    </div>
                  </div>
                  <div class="tab-pane" id="tab-3">
                    <div class="row">
                      <div class="col-lg-8 details order-2 order-lg-1" style={{ paddingTop: "53px" }}>
                        <p style={{ lineHeight: "30px" }} style={{ fontSize: "18px" }}>- Ranges from 30-250 GSM</p>
                      </div>
                      {/* <div class="col-lg-4 text-center order-1 order-lg-2">
                        <img src="assets/img/features-3.png" alt="" class="img-fluid" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div >
  )
}

export default Business
