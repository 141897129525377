import React, { useEffect } from 'react'

import { path } from './constants';

const Home = React.memo(() => {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [])

  return (
    <div>
      <section id="hero">
        <div id="heroCarousel" class="carousel slide carousel-fade" data-ride="carousel">

          <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active" style={{ backgroundImage: `url(${path}/img/1.jpg)` }}>
              <div class="carousel-container">
                <div class="container">
                  <h2 class="animate__animated animate__fadeInDown" >Welcome to <span>Ashima Fabrics</span></h2>
                  {/* <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                  <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a> */}
                </div>
              </div>
            </div>

            <div class="carousel-item" style={{ backgroundImage: `url(${path}/img/2.jpg)` }}>
              <div class="carousel-container">
                <div class="container">
                  {/* <h2 class="animate__animated animate__fadeInDown">Lorem Ipsum Dolor</h2>
                  <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                  <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a> */}
                </div>
              </div>
            </div>


            <div class="carousel-item" style={{ backgroundImage: `url(${path}/img/4.jpg)` }}>
              <div class="carousel-container">
                <div class="container">
                  {/* <h2 class="animate__animated animate__fadeInDown">Sequi ea ut et est quaerat</h2>
                  <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                  <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a> */}
                </div>
              </div>
            </div>

            <div class="carousel-item" style={{ backgroundImage: `url(${path}/img/5.jpg)` }}>
              <div class="carousel-container">
                <div class="container">
                  {/* <h2 class="animate__animated animate__fadeInDown">Sequi ea ut et est quaerat</h2>
                  <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
                  <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a> */}
                </div>
              </div>
            </div>
          </div>

          <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon icofont-simple-left" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>

          <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon icofont-simple-right" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>

        </div>
      </section>

      <div className="container pt-5">
        <h2 id="Range" data-aos="fade-up" class="pb-3 pt-5 pb-5" style={{ fontWeight: "bold", textAlign: "center" }}>
          Our Venture:
        </h2>
      </div>

      <div class="container">
        <div className="row">
          <div className="col-md container1">
            <img src={`${path}/img/22.jpg`} alt="Avatar" className="image1" />
            <div class="middle">
              <div class="text"><h3>Ashima Fabrics</h3><p>The company was founded in 1989 and operates in the areas of texturizing, twisting, sizing, weaving and export of finish fabrics.</p></div>
            </div>
          </div>
          <div className="col-md container1">
            <img src={`${path}/img/21.jpg`} alt="Avatar" className="image2" height="400px !important" />
            <div class="middle">
              <div class="text"><p>The manufacturing facilities are equipped with numerous machinery and the most modern technology for the production of Textiles</p></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
)

export default Home
