import React, { useEffect, useState } from 'react';
import axios from "axios";

function Contact() {
  const [characterLimit, setCharacterLimit] = useState(700);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);

  const onMessageChange = (e) => {
    const msg = e.target.value;
    if (msg.length < 700) {
      setMessage(msg);
      setCharacterLimit(700 - msg.length - 1);
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.get(
      `https://script.google.com/macros/s/AKfycbywFvuNUpbnsR8_8IGlBIBdcEjBhNav5lyUbxUCD70icNJ0ALk/exec?name=${name}&email=${email}&subject=${subject}&msg=${message}`
    ).then(e => {
      setLoading(false);
      console.log(e.data.data);
    })
      .catch(e => {
        setLoading(false);
      })

  }

  return (
    <div style={{ paddingTop: "50px" }}>
      <section id="contact" class="contact">
        <div class="container">
          {/* 21.3071213,72.8655405 */}
          <h3>Office Desk:</h3>
          <div>
            {/* <iframe style={{ border: "0", width: "100%", height: "270px" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe> */}
            <iframe style={{ border: "0", width: "100%", height: "270px" }} src="https://maps.google.com/maps?q=21.221691,72.838115&z=15&output=embed" frameborder="0" allowfullscreen></iframe>
          </div>
          <br />
          <h3>Factory Desk:</h3>
          <div>
            {/* <iframe style={{ border: "0", width: "100%", height: "270px" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe> */}
            <iframe style={{ border: "0", width: "100%", height: "270px" }} src="https://maps.google.com/maps?q=21.307179,72.867662&z=15&output=embed" frameborder="0" allowfullscreen></iframe>
          </div>

          <div class="row mt-5">

            <div class="col-lg-4">
              <div class="info">
                <div class="address">
                  <i class="icofont-google-map"></i>
                  <h4>Location:</h4>
                  <p> Office Desk : 238, RJD, Business Hub, B/h. Bada Ganesh, Opp. Kasanagar, Katargam, Surat <br /><br /> Factory Desk : P-1 To 8, Jay ambey Ind. Estate, Nr.Delad Gam, Sayan, Surat</p>
                </div>

                <div class="email">
                  <i class="icofont-envelope"></i>
                  <h4>Email:</h4>
                  <p>inquiry.ashima@gmail.com</p>
                </div>

                <div class="phone">
                  <i class="icofont-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 97123 40452</p>
                </div>

              </div>

            </div>

            <div class="col-lg-8 mt-5 mt-lg-0">

              <form onSubmit={e => onSubmitHandler(e)} class="php-email-form">
                <div class="form-row">
                  <div class="col-md-6 form-group">
                    <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" value={name} onChange={e => setName(e.target.value)} required />
                    <div class="validate"></div>
                  </div>
                  <div class="col-md-6 form-group">
                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" value={email} onChange={e => setEmail(e.target.value)} required />
                    <div class="validate"></div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" value={subject} onChange={e => setSubject(e.target.value)} required />
                  <div class="validate"></div>
                </div>
                <div class="form-group">
                  <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message" value={message} onChange={e => onMessageChange(e)} required></textarea>
                  <div class="validate"></div>
                </div>
                <div class="mb-3">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <p style={{ float: "right", fontSize: "12px" }}>{characterLimit} remaining</p>
                <div class="text-center">{loading ? <button type="submit" style={{ background: "#5a402e" }}>Sending...</button> : <button type="submit" style={{ background: "#5a402e" }}>Send Message</button>}</div>
              </form>

            </div>

          </div>

        </div>
      </section>
    </div>
  )
}

export default Contact
